import { FC, useEffect } from 'react';
 
import {
    Outlet
} from "react-router-dom";
 
const Newsletter:  FC = () => {  
    return (
        <>  
            <section className="ftco-section ftco-no-pb ftco-no-pt bg-secondary">
                <div className="container py-5">
                    <div className="row">
                    <div className="col-md-7 d-flex align-items-center">
                    <h2 className="mb-3 mb-sm-0" style={{ color:"black", fontSize: "22px" }}>
                        S'inscrire à notre newsletter </h2>
                    </div>
                    <div className="col-md-5 d-flex align-items-center">
                    <form action="#" className="subscribe-form">
                        <div className="form-group d-flex">
                        <input type="text" className="form-control" placeholder="Entrez votre adresse email" />
                        <input type="submit" value="S'inscrire" className="submit px-3" />
                        </div>
                    </form>
                    </div>
                </div>
                </div>
            </section>
        
        </>
    );
}

export default Newsletter