import { FC, useEffect, useState } from 'react';
 
import {
    Outlet, Link
} from "react-router-dom";
 
const Banner: FC<{ activePage: string }> = ( {activePage} ) => {  
    const [ loading, setLoading ] = useState(false);
    
    // useEffect(() => {
    //     setLoading(true);
    //     setTimeout(
    //         () => {
    //             setLoading(false);
    //         }, 1000
    //     );
    // }, []);

    return (
        <>  {
            !loading ?
            <section className="hero-wrap hero-wrap-2" 
                style={{ backgroundImage: "url('assets/images/banner.jpg')" }} data-stellar-background-ratio="0.5">
                <div className="overlay"></div>
                <div className="container">
                    <div className="row no-gutters slider-text align-items-end">
                        <div className="col-md-9  pb-5">
                            <p className="breadcrumbs mb-2"><span className="mr-2"><a href="/">Accueil 
                                <i className="ion-ios-arrow-forward"></i></a ></span> <span>{activePage} <i className="ion-ios-arrow-forward"></i></span></p>
                            <h1 className="mb-0 bread">{activePage}</h1>
                        </div>
                    </div>
                </div>
            </section> : <></>
        }
        </>
    );
}

export default Banner