import { FC, useEffect, useCallback, useRef, useState } from 'react';
 
import {
    Outlet, Link
} from "react-router-dom";

import Banner from '../../Layouts/Banner';
import Newsletter from '../../Layouts/Newsletter';

import { Formik, Field, Form, FormikProps } from 'formik'
import * as yup from 'yup'; 

import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import ContactService from './service';

interface ContactFormData {
    name?:string | null;
    email?: string | null;
    subject?: string | null;
    message?: string | null
}

const Contact: FC = () => {  

    const contactService = new ContactService();
    const formRef = useRef< FormikProps< ContactFormData >>(null);
    const containerStyle = {
        width: '100%',
        height: '400px'
      };
      
      const center = {
        lat: 6.124431786199432,
        lng: 1.2262183770179973
      };

      const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyDRxkAvJpEbNmZHrxIlnxlYwhUAR1QHkqg"
      })
    
      const [map, setMap] = useState(null)

      const [loading, setLoading] = useState(false);
      const [showSuccessDiv, setShowSuccessDiv] = useState(false);
    
      const onLoad = useCallback(function callback(map: any) {
        // This is just an example of getting and using the map instance!!! don't just blindly copy!
        const bounds = new window.google.maps.LatLngBounds(center);
        map.fitBounds(bounds);
    
        setMap(map)
      }, []);
    
      const onUnmount = useCallback(function callback(map: any) {
        setMap(null)
      }, []);

    return (
        <>  
            <Banner activePage={"Contact"} />
            		
            <section className="ftco-section bg-light">
                <div className="container">
                    <div className="row justify-content-center">
                            <div className="col-md-12">
                                <div className="wrapper">
                                    <div className="row no-gutters">
                                        <div className="col-lg-8 col-md-7 order-md-last d-flex align-items-stretch">
                                            <div className="contact-wrap w-100 p-md-5 p-4">
                                                <h3 className="mb-4"> Nous contacter </h3>
                                                <div id="form-message-warning" className="mb-4"></div> 
                                                { showSuccessDiv &&  <div className='container'>
               
                                                                    <div className='row contact-wrap w-100 p-md-5 p-4'>
                                                                        <div className='col-md-12' >
                                                                            <h2 style={{ textAlign: 'center' }}
                                                                             className="mb-4 center">
                                                                                <b> Message envoyé avec succès.</b></h2>
                                                                        </div>
                                                                    </div>
                                                                </div> }
                                                <Formik
                                                    initialValues={
                                                        {    
                                                            name: null,
                                                            email: null,
                                                            subject: null,
                                                            message: null
                                                    }}
                                                    validationSchema={
                                                        yup.object().shape({
                                                            name: yup
                                                                .string() 
                                                                .required('Ce champ est obligatoire'), 
                                                            email: yup
                                                                .string()  
                                                                .email('Email incorrect')
                                                                .required('Ce champ est obligatoire'),
                                                            subject: yup
                                                                .string(),
                                                            message: yup
                                                                .string()  
                                                                .required('Ce champ est obligatoire'),
                                                        })
                                                    }
                                                    innerRef={formRef}
                                                    onSubmit={async (
                                                        values 
                                                    ) => {
                                                        
                                                        setLoading(true);

                                                        var formValue  = values;

                                                        console.log(formValue);

                                                        contactService.sendContactMessage( formValue ).then(async function (response: any) {
                                                            console.log(response); 
                                                            setLoading(false); 
                                                            if (response.data.message === 'error') {
                                                                alert("Une erreur s'est produite le message n'a pas pu être envoyé.")
                                                            } else if (response.data.message === 'success') {
                                                                setShowSuccessDiv(true);
                                                                if (formRef.current !== null) {
                                                                    formRef.current.values.name = '';
                                                                    formRef.current.values.email = '';
                                                                    formRef.current.values.subject = '';
                                                                    formRef.current.values.message = '';
                                                                }
                                                            }
                                                            // 
                                                        })
                                                        .catch(function (error: any) {
                                                            console.log(error);
                                                            alert( 'Une erreur s\'est produite réesseyez s\'il vous plaît' )
                                                            setLoading(false); 
                                                        });
                                                    
                                                    }}  
                                                >

                                                {({ dirty, errors, touched, isValid, handleChange, handleBlur, handleSubmit, values }) => (
                                                <Form id="contactForm" name="contactForm" className="contactForm">  
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label className="label" htmlFor="name">Nom</label>
                                                                <input  className={`form-control ${ errors.name && touched.name ? "is-invalid":""}`}
                                                                        id="name" 
                                                                        name="name"
                                                                        onChange={(event: any) => { 
                                                                            handleChange('name')(event);
                                                                            // getProductByBarcode();
                                                                        }}
                                                                        onBlur={handleBlur('name')}
                                                                        value={values.name == null ? ''  :
                                                                         values.name }
                                                                        placeholder="Nom" />
                                                                    {   errors.name && touched.name &&
                                                                            errors.name && 
                                                                        <div    id="validationServer05Feedback" 
                                                                                className="invalid-feedback">
                                                                                { errors.name && touched.name &&
                                                                                errors.name }
                                                                        </div> 
                                                                    }
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6"> 
                                                            <div className="form-group">
                                                                <label className="label" htmlFor="email">Email</label>
                                                                <input className={`form-control ${ errors.email && touched.email ? "is-invalid":""}`}
                                                                        id="email" 
                                                                        name="email"
                                                                        onChange={(event: any) => { 
                                                                            handleChange('email')(event); 
                                                                        }}
                                                                        onBlur={handleBlur('email')}
                                                                        value={values.email == null ? ''  :
                                                                         values.email }
                                                                        placeholder="Email" />
                                                                    { errors.email && touched.email &&
                                                                     errors.email && 
                                                                <div id="validationServer05Feedback" 
                                                                className="invalid-feedback">
                                                                        { errors.email && touched.email &&
                                                                         errors.email }
                                                                </div> }
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="form-group">
                                                                <label className="label" htmlFor="subject">Sujet</label>
                                                                <input  className={`form-control 
                                                                ${ errors.subject && touched.subject ? "is-invalid":""}`}
                                                                        id="subject" 
                                                                        name="subject"
                                                                        onChange={(event: any) => { 
                                                                            handleChange('subject')(event);
                                                                            // getProductByBarcode();
                                                                        }}
                                                                        onBlur={handleBlur('subject')}
                                                                        value={values.subject == null ? '': values.subject }
                                                                        placeholder="Sujet" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="form-group">
                                                                <label className="label" htmlFor="#">Message</label>
                                                                <textarea name="message" 
                                                                 className={`form-control ${ errors.message
                                                                     && touched.message ? "is-invalid":""}`}
                                                                 id="message" 
                                                                cols={30} rows={4} 
                                                                onChange={(event: any) => { 
                                                                    handleChange('message')(event);
                                                                    // getProductByBarcode();
                                                                }}
                                                                onBlur={handleBlur('message')}
                                                                value={values.message == null ? ''  :
                                                                 values.message }
                                                                placeholder="Message"></textarea>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            { loading ?? <span style={{ color: 'red' }}> Un instant... </span> }
                                                            <div className="form-group">
                                                                
                                                                <button disabled={(!dirty && !isValid) } type="submit" className="btn btn-primary">
                                                                    Envoyer message
                                                                    {
                                                                        loading && <div className="spinner-border text-light spinner-border-sm" role="status">
                                                                            <span className="sr-only">Loading...</span>
                                                                        </div>
                                                                    }
                                                                </button>
                                                                <div className="submitting"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    </Form>
                                                )}
                                            </Formik>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-5 d-flex align-items-stretch">
                                            <div className="info-wrap bg-primary w-100 p-md-5 p-4">
                                                <h3>Nos adresses</h3>
                                                <p className="mb-4">Nous sommes disponibles aux adresses suivantes.</p>
                                        <div className="dbox w-100 d-flex align-items-start">
                                            <div className="icon d-flex align-items-center justify-content-center">
                                                <span className="fa fa-map-marker"></span>
                                            </div>
                                            <div className="text pl-3">
                                            <p><span>Adresse:</span> Montréal, QC</p>
                                        </div>
                                    </div>
                                        <div className="dbox w-100 d-flex align-items-center">
                                            <div className="icon d-flex align-items-center justify-content-center">
                                                <span className="fa fa-phone"></span>
                                            </div>
                                            <div className="text pl-3">
                                            <p><span>Téléphone:</span> <a href="tel://1234567920">+ 1235 2355 98</a></p>
                                        </div>
                                    </div>
                                        <div className="dbox w-100 d-flex align-items-center">
                                            <div className="icon d-flex align-items-center justify-content-center">
                                                <span className="fa fa-paper-plane"></span>
                                            </div>
                                            <div className="text pl-3">
                                            <p><span>Email:</span> <a href="mailto:info@yoursite.com">contact@aaic-global.org</a></p>
                                        </div>
                                    </div>
                                        <div className="dbox w-100 d-flex align-items-center">
                                            <div className="icon d-flex align-items-center justify-content-center">
                                                <span className="fa fa-globe"></span>
                                            </div>
                                            <div className="text pl-3">
                                            <p><span>Site web</span> <a href="#">https://aaic-global.org/</a></p>
                                        </div>
                                    </div>
                                </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
            </section>

            <div className="map">
                {
                    isLoaded ? (
                        <GoogleMap
                          mapContainerStyle={containerStyle}
                          center={center}
                          zoom={2}
                          onLoad={onLoad}
                          onUnmount={onUnmount}
                        >
                          { /* Child components, such as markers, info windows, etc. */ }
                          <></>
                        </GoogleMap> ) : <></>
                }
            </div>

            <Newsletter />
        </>
    );
}

export default Contact