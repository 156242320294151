import axiosInstance from '../../Utils/axios_manager';

export default class ContactService {
  
    constructor( ) {
    }

    public sendContactMessage =  (data: Record<string, any>) => {
        return axiosInstance.post('/postContactMessage.php', data);
    }
}