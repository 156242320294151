// PRODUCTION
console.log(process.env.NODE_ENV);
let _serverUrl = "dev.api.aaic-global.org/SITE";

if (process.env.NODE_ENV !== "development") {
    _serverUrl = "api.kekeliedu.net/AAIC/SITE";
}

// DEVELOPMENT
// let _serverUrl = "dev.api.aaic-global.org/SITE";

// "lapotnewapi.nogdevhouse.com";
// "localbackend.nogdevhouse.com";

// let apiProtocol = "http:";

// if (apiProtocol === undefined) {
//   apiProtocol = window.location.protocol;
// } 

let baseApiPath = "https://" + _serverUrl;

export const api_url = baseApiPath;
