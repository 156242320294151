import { FC, useEffect } from 'react';
 
import {
    Outlet, Link
} from "react-router-dom";
 
const Footer: FC = () => {  
    return (
        <>  
               <footer className="footer">
			<div className="container-fluid px-lg-5">
				<div className="row">
					<div className="col-md-9 py-5">
						<div className="row">
							<div className="col-md-4 mb-md-0 mb-4">
								<h2 className="footer-heading">A propos</h2>
								<img className="img-responsive" style={{width: '220px', height: '100px'}}
                                src="assets/images/logo.jpeg" alt="" />
								<br /> <br />
								<p>INVESTMENT CLUB nous sommes un groupe d'hommes et de femmes engagés dans l'investissement</p>
								<ul className="ftco-footer-social p-0">
		              <li className="ftco-animate"><a href="#" data-toggle="tooltip" data-placement="top" title="Twitter"><span className="fa fa-twitter"></span></a></li>
		              <li className="ftco-animate"><a href="#" data-toggle="tooltip" data-placement="top" title="Facebook"><span className="fa fa-facebook"></span></a></li>
		              <li className="ftco-animate"><a href="#" data-toggle="tooltip" data-placement="top" title="Instagram"><span className="fa fa-instagram"></span></a></li>
		            </ul>
							</div>
							<div className="col-md-8">
								<div className="row justify-content-center">
									<div className="col-md-12 col-lg-10">
										<div className="row">
											<div className="col-md-4 mb-md-0 mb-4">
												<h2 className="footer-heading">Services</h2>
												<ul className="list-unstyled">
						              <li><a href="#" className="py-1 d-block">Formation</a></li>
						              <li><a href="#" className="py-1 d-block">Financement</a></li>
						           
						            </ul>
											</div>
											<div className="col-md-4 mb-md-0 mb-4">
												<h2 className="footer-heading">Nous rejoindre</h2>
												<ul className="list-unstyled">
													<li><Link to="/devenir-un-membre" className="py-1 d-block">Devenir membre</Link></li>
									
												</ul>
											</div>
											<div className="col-md-4 mb-md-0 mb-4">
												<h2 className="footer-heading">Légales</h2>
												<ul className="list-unstyled">
						              <li><Link to="/mentions-legales" className="py-1 d-block">Mentions légales</Link></li> 
						              <li><Link to="/politiques-de-confidentialités" className="py-1 d-block"> Politiques de confidentialités </Link></li>
						            </ul>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="row mt-md-5">
							<div className="col-md-12">
								<p className="copyright">
					  Copyright &copy;<script>document.write(new Date().getFullYear());</script> Touts droits réservés |
					   Réalisé avec <i className="fa fa-heart" aria-hidden="true"></i> par <a href="#" target="_blank"> LORDEN SOFTWARE </a>
					   </p>
							</div>
						</div>
					</div>
					<div className="col-md-3 py-md-5 py-4 aside-stretch-right pl-lg-5">
						<h2 className="footer-heading">Nous conacter</h2>
						<p>Pour tous vos besoins ou demandes d'explications</p>
						<form action="#" className="form-consultation">
              <div className="form-group">
                <input type="text" className="form-control" placeholder="Votre nom" />
              </div>
              <div className="form-group">
                <input type="text" className="form-control" placeholder="Votre email" />
              </div>
              <div className="form-group">
                <input type="text" className="form-control" placeholder="Sujet" />
              </div>
              <div className="form-group">
                <textarea name="" id="" cols={30} rows={3} className="form-control" placeholder="Message"></textarea>
              </div>
              <div className="form-group">
              	<button type="submit" className="form-control submit px-3">Envoyer le message</button>
              </div>
            </form>
					</div>
				</div>
			</div>
		</footer>
        </>
    );
}

export default Footer