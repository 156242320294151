import { FC, useEffect } from 'react';
 
import {
    Outlet, Link
} from "react-router-dom";

import Banner from '../../Layouts/Banner';
import Newsletter from '../../Layouts/Newsletter'; 

import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';

const Advantages: FC = () => {  
    return (
        <>  
            <Banner activePage={"Que Gagnez-vous Avec Nous"} />
            <section className="ftco-section">
                <div className="container">
                    <div className="row">
                            <div className="col-md-12 col-lg-12 d-flex services align-self-stretch px-4  ">
                            {/* <div className="heading-section mb-5 mt-5 mt-lg-0">
    					<span className="subheading">FAQs</span>
	            		<h2 className="mb-3">Foire aux questions</h2>
    				</div> */}

					<Accordion defaultActiveKey="0">
									<Card>
										<Accordion.Toggle style={{ borderRadius: "4px", backgroundColor: '#edd663', color: "white" }} as={Card.Header} eventKey="0">
											<b> S'instruire en Eduction Financière </b>
										</Accordion.Toggle>
										<Accordion.Collapse eventKey="0">
										<Card.Body>
											Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quae recusandae ratione quam unde vitae voluptate nobis autem natus, quod eos dicta rem provident dolorem facere numquam ipsa ipsam nihil reiciendis.
										</Card.Body>
										</Accordion.Collapse>
									</Card>
									<Card>
										<Accordion.Toggle style={{ borderRadius: "4px", backgroundColor: '#edd663', color: "white" }} as={Card.Header} eventKey="1">
										 <b> Investir en Opportunités d'affaires </b>
										</Accordion.Toggle>
										<Accordion.Collapse eventKey="1">
										<Card.Body>
											Lorem ipsum dolor sit, amet consectetur adipisicing elit. Unde, sit. Mollitia quas eum ab doloribus, debitis cumque. Reprehenderit dolore consequuntur, adipisci dolorum consequatur, incidunt maiores rem dignissimos, minima iure ullam!
										</Card.Body>
										</Accordion.Collapse>
									</Card>
									<Card>
										<Accordion.Toggle style={{ borderRadius: "4px", backgroundColor: '#edd663', color: "white" }} 
										as={Card.Header} eventKey="2">
										 <b> S'enrichir en portefeuille de patrimoine financier </b>
										</Accordion.Toggle>
										<Accordion.Collapse eventKey="2">
										<Card.Body>
											Lorem ipsum dolor sit, amet consectetur adipisicing elit. Unde, sit. Mollitia quas eum ab doloribus, debitis cumque. Reprehenderit dolore consequuntur, adipisci dolorum consequatur, incidunt maiores rem dignissimos, minima iure ullam!
										</Card.Body>
										</Accordion.Collapse>
									</Card>

                                    <Card>
										<Accordion.Toggle style={{ borderRadius: "4px", backgroundColor: '#edd663', color: "white" }} 
										as={Card.Header} eventKey="3">
										 <b> S'inscrire en ligne pour rejoindre notre club </b>
										</Accordion.Toggle>
										<Accordion.Collapse eventKey="3">
										<Card.Body>
											Lorem ipsum dolor sit, amet consectetur adipisicing elit. Unde, sit. Mollitia quas eum ab doloribus, debitis cumque. Reprehenderit dolore consequuntur, adipisci dolorum consequatur, incidunt maiores rem dignissimos, minima iure ullam!
										</Card.Body>
										</Accordion.Collapse>
									</Card>
					</Accordion>
                            </div>
                 
                
                </div> 
                </div>
            </section>
            <Newsletter />
        </>
    );
}

export default Advantages