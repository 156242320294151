import { FC, useEffect } from 'react';
 
import {
    Outlet,  Link
} from "react-router-dom";
import { useAppSelector, useAppDispatch } from '../Hooks/customSelector';

 
const Header: FC = () => {  
    const routes = useAppSelector((state) => state.routes);
    useEffect(() => {
       // console.log(routes);
    }, [ routes ]);

    const smoothScroll = (elementId: string) => {
        document.querySelector('#'+elementId)?.scrollIntoView({
            behavior: 'smooth'
        });
    } 

    return (
        <>  
            <div id="header" className="wrap">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="bg-wrap">
                                <div className="row">
                                    <div className="col-md-6 d-flex align-items-center">
                                        <p className="mb-0 phone pl-md-2">
                                            <a href="#" className="mr-2"><span className="fa fa-phone mr-1"></span> +00 1234 567</a> 
                                            <a href="#"><span className="fa fa-paper-plane mr-1"></span> contact@aaic-global.org</a>
                                        </p>
                                    </div>
                                    <div className="col-md-6 d-flex justify-content-md-end">
                                        <div className="social-media">
                                        <p className="mb-0 d-flex">
                                            <a href="#" className="d-flex align-items-center justify-content-center">
                                                <span className="fa fa-facebook"><i className="sr-only">Facebook</i></span></a>
                                            <a href="#" className="d-flex align-items-center justify-content-center"><span className="fa fa-twitter"><i className="sr-only">Twitter</i></span></a>
                                            <a href="#" className="d-flex align-items-center justify-content-center"><span className="fa fa-instagram"><i className="sr-only">Instagram</i></span></a>
                                            <a href="#" className="d-flex align-items-center justify-content-center"><span className="fa fa-dribbble"><i className="sr-only">Dribbble</i></span></a>
                                        </p>
                                </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
           <nav className="navbar navbar-expand-lg navbar-dark ftco_navbar bg-dark ftco-navbar-light" id="ftco-navbar">
                <div className="container">
                    <a className="navbar-brand" href="index.html"> 
                        <img style={{ width: '150px', height: '80px' }} src="assets/images/logo.jpeg" alt="" />
                    </a>
                    <form action="#" className="searchform order-sm-start order-lg-last">
                        <div className="form-group d-flex">
                            <input type="text" className="form-control pl-3" placeholder="Rechercher" />
                            <button type="submit" placeholder="" className="form-control search"><span className="fa fa-search"></span></button>
                        </div> 
                        
                    </form>
                    <Link className='order-sm-start order-lg-last' to="/connexion" ><i style={{ color: "#edd663", marginLeft: "5px" }} 
                    className="fa fa-user-circle fa-2x "></i></Link>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#ftco-nav" aria-controls="ftco-nav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="fa fa-bars"></span> Menu
                </button>
                <div className="collapse navbar-collapse" id="ftco-nav">
                    <ul className="navbar-nav m-auto">
                        <li className={`nav-item ${ routes.currentPath == "/" ? "active" : "" } `}
                        ><Link to="/" className="nav-link"> Accueil</Link></ li>
                        {/* <li className={`nav-item ${ routes.currentPath == "/qui-sommes-nous" ? "active" : "" } `} >
                            <Link to="/qui-sommes-nous" className="nav-link">Qui sommes-nous</Link>
                        </li> */}
                        <li className={`nav-item dropdown ${ routes.currentRoute.includes("qui-sommes-nous") ? "active" : "" } `} >
                            <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Qui sommes-nous
                            </a>
                            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                            <Link onClick={ event => { smoothScroll('notreHistorique'); } } 
                            className="dropdown-item" to="/qui-sommes-nous">Notre historique</Link> 
                            <Link onClick={ event => { smoothScroll('notreVision'); } } 
                            className="dropdown-item" to="/qui-sommes-nous">Notre vision</Link>
                            <Link onClick={ event => { smoothScroll('notreMission'); } } 
                            className="dropdown-item" to="/qui-sommes-nous">Notre mission</Link>  
                            <Link onClick={ event => { smoothScroll('notreConseilAdmin'); } } 
                            className="dropdown-item" to="/qui-sommes-nous">Notre conseil d'administration</Link> 
                            <Link onClick={ event => { smoothScroll('notreDocumentation'); } } 
                            className="dropdown-item" to="/qui-sommes-nous">Notre documentation</Link> 
                            {/* <a className="dropdown-item" href="#">Another action</a> */}
                            <div className="dropdown-divider"></div>
                            <Link onClick={ event => { smoothScroll('notreFaq'); } }
                             className="dropdown-item" to="/qui-sommes-nous">FAQ</Link>
                            </div>
                        </li> 
                        <li className={`nav-item ${ routes.currentPath == "/que-faisons-nous" ? "active" : "" } `} >
                            <Link to="/que-faisons-nous" className="nav-link">Que Faisons-Nous</Link>
                        </li>

                        <li className={`nav-item ${ routes.currentPath == "/que-gagnez-vous-avec-nous" ? "active" : "" } `} >
                            <Link to="/que-gagnez-vous-avec-nous" className="nav-link">Avantages</Link>
                        </li>

                        {/* <li className={`nav-item dropdown ${ routes.currentRoute.includes("qui-sommes-nous") ? "active" : "" } `} >
                            <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Qui sommes-nous
                            </a>
                            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                            <Link to="/qui-sommes-nous/vision" className="dropdown-item">Notre vision</Link> 
                            <a className="dropdown-item" href="#">Another action</a>
                            <div className="dropdown-divider"></div>
                            <a className="dropdown-item" href="#">Something else here</a>
                            </div>
                        </li> 
                        <li  className={`nav-item ${ routes.currentPath == "/devenir-un-membre" ? "active" : "" } `}><Link to="/devenir-un-membre" className="nav-link">Devenir membre</Link></li>
                        */}
                        <li className={`nav-item ${ routes.currentPath == "/contact" ? "active" : "" } `}><Link to="/contact" className="nav-link">Contact</Link></li>
                    </ul>
                </div>
                </div>
            </nav>
        </>
    );
}

export default Header