import { FC, useEffect, useState } from 'react';
 
import {
    Outlet
} from "react-router-dom";
import { useAppSelector, useAppDispatch } from '../Hooks/customSelector';

import Header from '../Layouts/Header';
import Footer from '../Layouts/Footer';
import translateText from '../GoogleTranslate';


const Site: FC = () => {  
    const routes = useAppSelector((state) => state.routes);
    let [ lastRoute, setLastRoute ] = useState("/");

    const [inputText, setInputText] = useState('');
    const [targetLanguage, setTargetLanguage] = useState('fr'); // Default: Spanish
  
    const handleTranslate = async () => {
        
      if (inputText) {
        console.log('Language translation on going...');
        const translatedText = await translateText(inputText, targetLanguage);
        console.log(translatedText);
        console.log('Language translation finished...'); 
      }
    };

    const smoothScroll = (elementId: string) => {
        document.querySelector('#'+elementId)?.scrollIntoView({
            behavior: 'smooth'
        });
    }
    
    useEffect(() => {
        // console.log(routes);

        // window.scrollTo(0, 0);
        smoothScroll("header");
        
        if (routes.currentPath == "/" && lastRoute != routes.currentPath) {
            window.location.reload();
        }
        setLastRoute(routes.currentPath);

    }, [ routes ]);

    // useEffect(() => {
    //     if (!document.querySelector('.skiptranslate')) {

    //         var addScript = document.createElement('script');
    //         addScript.setAttribute(
    //         'src',
    //         '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit'
    //         );
    //         document.body.appendChild(addScript);
    //         window.googleTranslateElementInit = googleTranslateElementInit;
    //     }
        
    //   }, []);
    
    //   const googleTranslateElementInit = () => {
    //     new window.google.translate.TranslateElement(
    //       {
    //         pageLanguage: 'fr',
    //         includedLanguages: 'fr,en', // include this for selected languages
    //         layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE,
    //         autoDisplay: false
    //       },
    //       'google_translate_element'
    //     ); 
    //   };

    return (
        <>  
            {/* <div id="google_translate_element"> </div> */}
            <Header />
            <Outlet />
            <Footer />
        </>
    );
}

export default Site