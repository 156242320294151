import { FC, useEffect } from 'react';
 
import {
    Outlet, Link
} from "react-router-dom";

import Banner from '../../Layouts/Banner';
import Newsletter from '../../Layouts/Newsletter'; 

import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';

const Termes: FC = () => {  
    return (
        <>  
            <Banner activePage={"Termes et conditions d'utilisation"} />
            <section className="ftco-section">
            <div className="container main-wrapper"> 
	<div className="lte-text-page margin-default">
        <div className="row centered">
        	            <div className="col-xl-12 col-xs-12">
				<article id="post-15967" className="post-15967 page type-page status-publish hentry">
	<div className="entry-content clearfix" id="entry-div">
				<div data-elementor-type="wp-page" data-elementor-id="15967" className="elementor elementor-15967">
						<div className="elementor-inner">
				<div className="elementor-section-wrap">
									<section className="elementor-section elementor-top-section elementor-element elementor-element-10d77d5 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="10d77d5" data-element_type="section">
						<div className="elementor-container elementor-column-gap-default">
							<div className="elementor-row">
					<div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-f61e2a4" data-id="f61e2a4" data-element_type="column">
			<div className="elementor-column-wrap elementor-element-populated">
							<div className="elementor-widget-wrap">
						<div className="elementor-element elementor-element-6d9e4a9 elementor-widget elementor-widget-html" data-id="6d9e4a9" data-element_type="widget" data-widget_type="html.default">
				<div className="elementor-widget-container">
			<ol>
	<li><b>Acceptation des Conditions</b></li>
	<p>En accédant au site web de AAIC GLOBAL, vous acceptez de vous conformer aux présentes Conditions Générales d'Utilisation.</p>
	<li><b>Utilisation du Site</b></li>
	<p>Vous vous engagez à utiliser le site dans le respect des lois et règlements en vigueur. Toute utilisation à des fins illégales ou malveillantes est strictement interdite.</p>
	<li><b>Propriété Intellectuelle</b></li>
	<p>Le contenu du site, y compris les textes, images, logos, et autres éléments, est protégé par les lois sur la propriété intellectuelle. Toute reproduction ou utilisation non autorisée est interdite.</p>
	<li><b>Responsabilités</b></li>
	<p>AAIC GLOBAL s'efforce de fournir des informations exactes et à jour, mais ne garantit pas l'exactitude ni l'exhaustivité du contenu. AAIC GLOBAL décline toute responsabilité en cas d'interruption ou de dysfonctionnement du site.</p>
	<li><b>Liens vers des Sites Tiers</b></li>
	<p>Le site peut contenir des liens vers des sites tiers. AAIC GLOBAL n'assume aucune responsabilité quant au contenu ou à <Link style={{ color: 'primary', textDecoration: 'underline' }} to='/politiques-de-confidentialités' >la politique de confidentialité</Link> de ces sites.</p>
	<li><b>Protection des Données Personnelles</b></li>
	<p>Les données personnelles collectées sont traitées conformément à la politique de confidentialité disponible sur le site.</p>
	<li><b>Modifications des CGU</b></li>
	<p>AAIC GLOBAL se réserve le droit de modifier les présentes CGU à tout moment. Les utilisateurs seront informés des changements.</p>
	<li><b>Loi Applicable et Juridiction</b></li>
	<p>Les présentes CGU sont régies par la loi togolaise. Tout litige relatif à l'utilisation du site sera de la compétence des tribunaux compétents.</p>
	<p>En utilisant le site, vous acceptez l'ensemble de ces conditions. Si vous n'acceptez pas ces conditions, veuillez ne pas utiliser le site.</p>
</ol>
		</div>
				</div>
						</div>
					</div>
		</div>
								</div>
					</div>
		</section>
									</div>
			</div>
					</div>
			</div>
</article>
            </div>
        	        
        </div>
	</div>

</div>
            </section>
            <Newsletter />
        </>
    );
}

export default Termes