import { FC, useEffect } from 'react';
 
import {
    Outlet, Link
} from "react-router-dom";

import Banner from '../../Layouts/Banner';
import Newsletter from '../../Layouts/Newsletter';

import Accordion from 'react-bootstrap/Accordion';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';


const Whatwedo: FC = () => {  
    return (
        <>  
            <Banner activePage={"Que Faisons-Nous"} />
            
            <section className="ftco-section">
            <p style={{ textAlign: "center" }} className='center'>
                <b>Nous intervenons sur plusieurs pôles, voici les principaux.</b>
            </p>
            <br />
                <div className="container">
                    <div className="row">
                <div className="col-md-6 col-lg-3 d-flex services align-self-stretch px-4  ">
                    <div className="d-block">
                    <div className="icon d-flex mr-2">
                            <span className="flaticon-accounting-1"></span>
                    </div>
                    <div className="media-body">
                        <h3 className="heading">Développement Personnel</h3> 
                    </div>
                    </div>      
                </div>
                <div className="col-md-6 col-lg-3 d-flex services align-self-stretch px-4  ">
                    <div className="d-block">
                    <div className="icon d-flex mr-2">
                            <span className="flaticon-tax"></span>
                    </div>
                    <div className="media-body">
                        <h3 className="heading">Épargne et Tontines Financieres</h3> 
                    </div>
                    </div>    
                </div>
                <div className="col-md-6 col-lg-3 d-flex services align-self-stretch px-4  ">
                    <div className="d-block">
                    <div className="icon d-flex mr-2">
                            <span className="flaticon-loan"></span>
                    </div>
                    <div className="media-body">
                        <h3 className="heading">Marché des Capitaux (Actions et Obligations)</h3> 
                    </div>
                    </div>      
                </div>
                <div className="col-md-6 col-lg-3 d-flex services align-self-stretch px-4  ">
                    <div className="d-block">
                    <div className="icon d-flex mr-2">
                            <span className="flaticon-budget"></span>
                    </div>
                    <div className="media-body">
                        <h3 className="heading">Immobilier (Résidentiel et Commercial)</h3> 
                    </div>
                    </div>      
                </div>
                
                </div>
                <br /><br />
                <div className='row'>
                    <div className="col-md-6 col-lg-3 d-flex services align-self-stretch px-4  ">
                        <div className="d-block">
                        <div className="icon d-flex mr-2">
                                <span className="flaticon-budget"></span>
                        </div>
                        <div className="media-body">
                            <h3 className="heading">Entrepreneuriat</h3> 
                        </div>
                        </div>      
                    </div>
                </div>
                </div>
            </section>
            <Newsletter />
        </>
    );
}

export default Whatwedo