import React from 'react';
import logo from './logo.svg';
import './App.css';


import {
  BrowserRouter as Router,
  Route,
  Link,
  Routes,
  Navigate
} from "react-router-dom";

import Site from './Core/Site';
// Pages
import Track from './Pages/Track';
import Home from './Pages/Home';
import About from './Pages/About';
import Register from './Pages/Register';
import Contact from './Pages/Contact';
import Vision from './Pages/About/vision';
import Whatwedo from './Pages/Whatwedo';
import Advantages from './Pages/Advantages';
import Connexion from './Pages/Connexion';
import Confidential from './Pages/Confidential';
import Legal from './Pages/Legal';
import Termes from './Pages/Termes';

function App() {
  return (
    <Router> 
      <Track />
      <Routes>
        <Route path="/" element={<Site />} >

          <Route index element={<Home />} />

          {/* About  */}
          <Route path="qui-sommes-nous" element={<About />} />

          {/* About  */}
          <Route path="que-faisons-nous" element={<Whatwedo />} />

          {/* Advantages  */}
          <Route path="que-gagnez-vous-avec-nous" element={<Advantages />} />

          {/* Connexion  */}
          <Route path="connexion" element={<Connexion />} />
             
          {/* Register  */}
          <Route path="devenir-un-membre" element={<Register />} />

          {/* Confidential  */}
          <Route path="politiques-de-confidentialités" element={<Confidential />} />

          {/* Legal  */}
          <Route path="mentions-legales" element={<Legal />} />

          {/* Termes et conditions  */}
          <Route path="termes-et-conditions-utilisations" element={<Termes />} />

          {/* Contact  */}
          <Route path="contact" element={<Contact />} />

        </Route>
      </Routes>

    </Router>
  );
}

export default App;
