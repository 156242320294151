import { FC, useEffect, useRef, useState } from 'react';
 
import {
    Outlet, Link
} from "react-router-dom";

import Banner from '../../Layouts/Banner';
import Newsletter from '../../Layouts/Newsletter';


import { Formik, Field, Form, FormikProps } from 'formik'
import * as yup from 'yup'; 
import RegisterService from './service';


// React - Bootstrap
import { Modal } from 'react-bootstrap';

interface User {
    status?: string | null;
    centerOfInterest?: string | null;
    termes: boolean;
    firstname: string;
    lastname: string;
    email: string;
    phone_number: string;
    birth_date: string;
    country: string;
    residence_country: string;
    nationality: string;
    adress: string;
    profession: string;
    sponsor: string;
}

const Register: FC = () => {  

    const registerService = new RegisterService();

    const formRef = useRef< FormikProps< User >>(null);

    const countries_init: any = [];
    const country_phone_code_init: string = '';

    const [
        countries, setCountries
    ] = useState(countries_init);

    const [
        countryPhoneCode, setCountryPhoneCode
    ] = useState(country_phone_code_init);

    const [
        loadCountries, setLoadingCountries
    ] = useState(false);

    const [
        loading, setLoading
    ] = useState(false);

    const [
        termesShow, setTermesShow
    ] = useState(false);

    const [
        showSuccessDiv, setShowSuccessDiv
    ] = useState(false);

    const getCountries = () => {

        setLoadingCountries(true);

        registerService.getCountries().then(async function (response: any) {
            console.log(response); 
            setCountries(response.data.countries);
            setLoadingCountries(false);  
        })
        .catch(function (error: any) {
            console.log(error);
            alert( 'Une erreur s\'est produite réesseyez s\'il vous plaît' )
            // setLoadingCountries(false); 
        });

    }

    const smoothScroll = (elementId: string) => {
        document.querySelector('#'+elementId)?.scrollIntoView({
            behavior: 'smooth'
        });
    }


    useEffect(function() { 
        getCountries();
    }, [])


    return (
        <>  
            <Banner activePage={"Devenir un membre"} />
                <section id="registerTop" className="ftco-section ftco-no-pt bg-light">
                <br />
                { showSuccessDiv &&  <div className='container'>
               
                    <div className='row contact-wrap w-100 p-md-5 p-4'>
                        <div className='col-md-12' >
                            <h2 style={{ textAlign: 'center' }} className="mb-4 center">
                                <b> Inscirption réussie.</b></h2>
                            <p className='text-center'>
                                AAIC GLOBAL vous remercie pour votre inscription, 
                                notre équipe va vous contacter très bientôt enfin de complèter 
                                votre inscrption et répondre à toutes vos éventuelles inquiètudes. 
                            </p>
                            <br/>
                            <p className='text-center'>
                                <a className="btn btn-primary "  href="/">
                                    Terminer
                                </a>
                            </p>
                            
                        </div>
                    </div>
                </div> }
                { !showSuccessDiv && <div className="container">
                    <h2 style={{ textAlign: 'center' }} className="mb-4 center">
                        <b> Remplissez le formulaire </b></h2>
                        <p style={{ textAlign: 'center' }}>Vous devez obligatoirement nous fournir toutes les informations demandées sur ce formulaire</p>
                    <div  className="contact-wrap w-100 p-md-5 p-4" >
                    <Formik validateOnChange={true}
                                    initialValues={
                                        {   
                                            status: null,
                                            centerOfInterest: null,
                                            termes: false,
                                            firstname: '',
                                            lastname: '',
                                            email: '',
                                            phone_number: '',
                                            birth_date: '',
                                            country: '',
                                            residence_country: '',
                                            nationality: '', 
                                            adress: '',
                                            profession: '',
                                            sponsor: ''
                                    }}
                                    validationSchema={
                                        yup.object().shape({
                                            status: yup
                                                .string() 
                                                .required('Ce champ est obligatoire'),
                                            centerOfInterest: yup
                                                .mixed<string>()
                                                .required('Ce champ est obligatoire'),
                                            firstname: yup
                                                .string() 
                                                .required('Ce champ est obligatoire'),
                                            lastname: yup
                                                .string() 
                                                .required('Ce champ est obligatoire'),
                                            email: yup
                                                .string() 
                                                .email('Email incorrect')
                                                .required('Ce champ est obligatoire')
                                                .test('checkEmailUnique', "Cet email a été déjà utilisé", async value =>
                                                    registerService.verifyEmail(value ?? '')
                                                    .then((res) => { 
                                                        console.log(res);
                                                        return  res.data.exist;
                                                    })
                                                    .catch((e) => {
                                                        console.log(e);
                                                        return false;
                                                    })
                                                ),
                                            phone_number: yup
                                                .string() 
                                                .required('Ce champ est obligatoire')
                                                .test('checkPhoneNumberUnique', "Ce numéro de téléphone a été déjà utilisé", async value =>
                                                    registerService.verifyPhoneNumber(( (countryPhoneCode ?? '') + value ) ?? '')
                                                    .then((res) => {
                                                        return  res.data.exist;
                                                    })
                                                    .catch((e) => {
                                                        console.log(e);
                                                        return false;
                                                    })
                                                ),
                                            birth_date: yup
                                                .date() 
                                                .required('Ce champ est obligatoire'),
                                            country: yup
                                                .string() 
                                                .required('Ce champ est obligatoire'),
                                            residence_country: yup
                                                .string() 
                                                .required('Ce champ est obligatoire'),
                                            nationality: yup
                                                .string() 
                                                .required('Ce champ est obligatoire'), 
                                            adress: yup
                                                .string() 
                                                .required('Ce champ est obligatoire'), 
                                            profession: yup
                                                .string() 
                                                .required('Ce champ est obligatoire'),
                                            sponsor: yup
                                                .string(),
                                            termes: yup
                                                .boolean() 
                                                .required('Ce champ est obligatoire'),
                                         
                                        })
                                    }
                                    innerRef={formRef}
                                    onSubmit={(
                                        values 
                                    ) => {
                                        
                                        setLoading(true);

                                        var formValue  = values;

                                        formValue.phone_number = countryPhoneCode + formValue.phone_number;

                                        // console.log(values); 
                                            registerService.register( formValue ).then(async function (response: any) {
                                                console.log(response); 
                                                setLoading(false); 
                                                setShowSuccessDiv(true);
                                            })
                                            .catch(function (error: any) {
                                                console.log(error);
                                                alert( 'Une erreur s\'est produite réesseyez s\'il vous plaît' )
                                                setLoading(false); 
                                            });
                                      }}  
                                >

                                {({ dirty, errors, touched, isValid, handleChange, handleBlur, handleSubmit, values }) => (
                                <Form className=" row contactForm" > 

                                <div className="form-group col-md-12">
                                    <label htmlFor="">Êtes-vous un nouveau ou un ancien membre ?</label> <br/>

                                    <div role="group" aria-labelledby="my-radio-group">
                                        <label>
                                            <Field type="radio" name="status" value="new" />
                                            Je suis un nouveau membre
                                        </label>
                                        <br/>
                                        <label>
                                            <Field type="radio" name="status" value="old" />
                                            Je suis un ancien membre
                                        </label> 
                                        <br/>
                                        <label>
                                            <Field type="radio" name="status" value="" />
                                            Je ne sais pas
                                        </label> 
                                    </div>
 
                                </div>
                                <div className="form-group col-md-6">
                                    <label htmlFor="">Prénom</label>
                                    <input  className={`form-control ${ errors.firstname && touched.firstname ? "is-invalid":""}`}
                                                                        id="firstname" 
                                                                        name="firstname"
                                                                        onChange={(event: any) => { 
                                                                            handleChange('firstname')(event);
                                                                            // getProductByBarcode();
                                                                        }}
                                                                        onBlur={handleBlur('firstname')}
                                                                        value={values.firstname == null ? ''  :
                                                                         values.firstname }
                                                                        placeholder="Prénom" />
                                                                    { errors.firstname && touched.firstname &&
                                                                     errors.firstname && 
                                                                <div id="validationServer05Feedback" 
                                                                className="invalid-feedback">
                                                                        { errors.firstname && touched.firstname &&
                                                                         errors.firstname }
                                                                </div> }
                            </div>
                            <div className="form-group col-md-6">
                                <label htmlFor="">Nom</label>
                                <input  className={`form-control ${ errors.lastname && touched.lastname ? "is-invalid":""}`}
                                                                        id="lastname" 
                                                                        name="lastname"
                                                                        onChange={(event: any) => { 
                                                                            handleChange('lastname')(event);
                                                                            // getProductByBarcode();
                                                                        }}
                                                                        onBlur={handleBlur('lastname')}
                                                                        value={values.lastname == null ? ''  :
                                                                         values.lastname }
                                                                        placeholder="Nom" />
                                                                    { errors.lastname && touched.lastname &&
                                                                     errors.lastname && 
                                                                <div id="validationServer05Feedback" 
                                                                className="invalid-feedback">
                                                                        { errors.lastname && touched.lastname &&
                                                                         errors.lastname }
                                                                </div> }
                            </div>

                            <div className="form-group col-md-6">
                                <label htmlFor="">Pays d'origine</label>
                                {   loadCountries ? <div className="spinner-border text-light spinner-border-sm" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div> : <select className={`form-control custom-select ${ errors.country &&
                                                                    touched.country ? "is-invalid" : "" }`}
                                                                id="country" 
                                                                name="country"
                                                                onChange={event => { 
                                                                    handleChange('country')(event); 
                                                                }}
                                                                onBlur={handleBlur('country')}
                                                                value={values.country == null ? '' : values.country}
                                                                placeholder="Pays d'origine" >
                                    <option value="">Pays d'origine</option>

                                    {
                                        countries.map((country: any, index: number) => <option key={index} value={ country.nicename }>{ country?.nicename }</option>)
                                    }
                                    {/* <option value="Togo">Togo</option>
                                    <option value="Bénin">Bénin</option>
                                    <option value="Ghana">Ghana</option>
                                    <option value="Burkina-Faso">Burkina-Faso</option> */}
                                </select> }
                            </div>

                            <div className="form-group col-md-6">
                                <label htmlFor="">Pays de résidence</label>
                                {   loadCountries ? <div className="spinner-border text-light spinner-border-sm" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div> : <select className={`form-control custom-select ${ errors.residence_country &&
                                                                    touched.residence_country ? "is-invalid" : "" }`}
                                                                id="residence_country" 
                                                                name="residence_country"
                                                                onChange={event => { 
                                                                    handleChange('residence_country')(event); 
                                                                    
                                                                    if (event.target.value !== '') {
                                                                        const ctry: Array<any> =  countries.filter((crt: any) =>  crt.nicename === event.target.value);
                                                                        if (ctry.length > 0) setCountryPhoneCode(ctry[0].phonecode);
                                                                        else setCountryPhoneCode('');
                                                                    }
                                                                }}
                                                                onBlur={handleBlur('residence_country')}
                                                                value={values.residence_country == null ? '' : values.residence_country}
                                                                placeholder="Pays de résidence" >
                                    <option value="">Pays de résidence</option>
                                    {
                                        countries.map((country: any, index: number) => <option key={index} value={ country.nicename }>{ country?.nicename }</option>)
                                    }
                                    {/* <option value="Togo">Togo</option>
                                    <option value="Bénin">Bénin</option>
                                    <option value="Ghana">Ghana</option>
                                    <option value="Burkina-Faso">Burkina-Faso</option> */}
                                </select> }
                            </div>


                            


                            <div className="form-group col-md-6">
                                <label htmlFor="">Nationalité</label>
                                <input  className={`form-control ${ errors.nationality && touched.nationality ? "is-invalid":""}`}
                                                                        id="nationality" 
                                                                        name="nationality"
                                                                        onChange={(event: any) => { 
                                                                            handleChange('nationality')(event); 
                                                                        }}
                                                                        onBlur={handleBlur('nationality')}
                                                                        value={values.nationality == null ? ''  :
                                                                         values.nationality }
                                                                        placeholder="Nationalité" />
                                                                    { errors.nationality && touched.nationality &&
                                                                     errors.nationality && 
                                                                <div id="validationServer05Feedback" 
                                                                className="invalid-feedback">
                                                                        { errors.nationality && touched.nationality &&
                                                                         errors.nationality }
                                                                </div> }
                            </div>

                            <div className="form-group col-md-6">
                                <label htmlFor="">Date de naissance</label>
                                <input type="date" className={`form-control ${ errors.birth_date && touched.birth_date ? "is-invalid":""}`}
                                                                        id="birth_date" 
                                                                        name="birth_date"
                                                                        onChange={(event: any) => { 
                                                                            handleChange('birth_date')(event); 
                                                                        }}
                                                                        onBlur={handleBlur('birth_date')}
                                                                        value={values.birth_date == null ? ''  :
                                                                         values.birth_date }
                                                                        placeholder="Date de naissance" />
                                                                    { errors.birth_date && touched.birth_date &&
                                                                     errors.birth_date && 
                                                                <div id="validationServer05Feedback" 
                                                                    className="invalid-feedback">
                                                                        { errors.birth_date && touched.birth_date &&
                                                                         errors.birth_date }
                                                                </div> }
                            </div>

                            <div className="form-group col-md-6">
                                <label htmlFor="">Numéro de téléphone</label>
                                <div className="input-group mb-2" >
                                    <div className="input-group-prepend">
                                        <div className="input-group-text" >{ countryPhoneCode }</div>
                                    </div>

                                    <input style={{ marginLeft: 10 }} type='tel'  className={`form-control ${ errors.phone_number && touched.phone_number ? "is-invalid":""}`}
                                                                        id="phone_number" 
                                                                        name="phone_number"
                                                                        onChange={(event: any) => { 
                                                                            handleChange('phone_number')(event); 
                                                                        }}
                                                                        onBlur={handleBlur('phone_number')}
                                                                        value={values.phone_number == null ? ''  :
                                                                         values.phone_number }
                                                                        placeholder="Numéro de téléphone" />
                                                                    { errors.phone_number && touched.phone_number &&
                                                                     errors.phone_number && 
                                                                <div id="validationServer05Feedback" 
                                                                className="invalid-feedback">
                                                                        { errors.phone_number && touched.phone_number &&
                                                                         errors.phone_number }
                                                                </div> }
                                </div>
                            </div>


                            <div className="form-group col-md-6">
                                <label htmlFor="">Email</label>
                                <input className={`form-control ${ errors.email && touched.email ? "is-invalid":""}`}
                                                                        id="email" 
                                                                        name="email"
                                                                        onChange={(event: any) => { 
                                                                            handleChange('email')(event); 
                                                                        }}
                                                                        onBlur={handleBlur('email')}
                                                                        value={values.email == null ? ''  :
                                                                         values.email }
                                                                        placeholder="Email" />
                                                                    { errors.email && touched.email &&
                                                                     errors.email && 
                                                                <div id="validationServer05Feedback" 
                                                                className="invalid-feedback">
                                                                        { errors.email && touched.email &&
                                                                         errors.email }
                                                                </div> }
                            </div>
 
                            <div className="form-group col-md-6">
                                <label htmlFor="">Adresse</label>
                                <input  className={`form-control ${ errors.adress && touched.adress ? "is-invalid":""}`}
                                                                        id="adress" 
                                                                        name="adress"
                                                                        onChange={(event: any) => { 
                                                                            handleChange('adress')(event); 
                                                                        }}
                                                                        onBlur={handleBlur('adress')}
                                                                        value={values.adress == null ? ''  :
                                                                         values.adress }
                                                                        placeholder="Adresse" />
                                                                    { errors.adress && touched.adress &&
                                                                     errors.adress && 
                                                                <div id="validationServer05Feedback" 
                                                                    className="invalid-feedback">
                                                                        { errors.adress && touched.adress &&
                                                                         errors.adress }
                                                                </div> }
                            </div>
 

                            <div className="form-group col-md-6">
                                <label htmlFor="">Profession</label>
                                <input className={`form-control ${ errors.profession && touched.profession ? "is-invalid":""}`}
                                                                        id="profession" 
                                                                        name="profession"
                                                                        onChange={(event: any) => { 
                                                                            handleChange('profession')(event);
                                                                            // getProductByBarcode();
                                                                        }}
                                                                        onBlur={handleBlur('profession')}
                                                                        value={values.profession == null ? ''  :
                                                                         values.profession }
                                                                        placeholder="Profession" />
                                                                    { errors.profession && touched.profession &&
                                                                     errors.profession && 
                                                                <div id="validationServer05Feedback" 
                                                                    className="invalid-feedback">
                                                                        { errors.profession && touched.profession &&
                                                                         errors.profession }
                                                                </div> }
                            </div>

                            <div className="form-group col-md-6">
                                <label htmlFor="">Sponsor</label>
                                <input className={`form-control ${ errors.sponsor && touched.sponsor ? 
                                "is-invalid":""}`}
                                                                        id="sponsor" 
                                                                        name="sponsor"
                                                                        onChange={(event: any) => { 
                                                                            handleChange('sponsor')(event);
                                                                            // getProductByBarcode();
                                                                        }}
                                                                        onBlur={handleBlur('sponsor')}
                                                                        value={values.sponsor == null ? ''  :
                                                                         values.sponsor }
                                                                        placeholder="Sponsor" />
                                                                    { errors.sponsor && touched.sponsor &&
                                                                     errors.sponsor && 
                                                                <div id="validationServer05Feedback" 
                                                                    className="invalid-feedback">
                                                                        { errors.sponsor && touched.sponsor &&
                                                                         errors.sponsor }
                                                                </div> }
                            </div>

                            <div className="form-group col-md-12">
                                    <label htmlFor="">Quel sont vos centres d'intérêt ? </label> <br/>

                                    <div style={{ marginLeft: "10px" }} role="group" aria-labelledby="checkbox-group">
                                        <label>
                                            <Field type="checkbox" name="centerOfInterest" value="Développement Personnel" />
                                            Développement Personnel
                                        </label>
                                        <br/>
                                        <label>
                                            <Field type="checkbox" name="centerOfInterest" value="Formation sur l'Épargne et la Tontine" />
                                            Formation sur l'Épargne et la Tontine
                                        </label>
                                        <br/>
                                        <label>
                                            <Field type="checkbox" name="centerOfInterest" value="Initiation aux marchés des valeurs obligataires et participatives (Actions/Obligations)" />
                                            Initiation aux marchés des valeurs obligataires et participatives (Actions/Obligations)
                                        </label>
                                        <br/>
                                        <label>
                                            <Field type="checkbox" name="centerOfInterest" value="Coaching Immobilier" />
                                            Coaching Immobilier
                                        </label>
                                        <br/>
                                        <label>
                                            <Field type="checkbox" name="centerOfInterest" value="Accompagnement financier de StartUps" />
                                            Accompagnement financier de StartUps
                                        </label>
                                    </div>

                                </div>

                            <br />
                            <br />
                            <label>
                                <Field type="checkbox" name="termes" />
                                J'ai lu et j'accepte <span onClick={() => setTermesShow(true)} 
                                    style={{ color: '#edd663' }} >
                                    les termes et conditions d'utilisation</span>
                            </label>
                            <br />
                            <br />

                            <div className="col-md-12">
                                <div className="form-group">
                                    <button disabled={(!dirty && !isValid) ||
                                        countryPhoneCode === '' || values.termes === false } 
                                        type="submit" className="btn btn-primary" onClick={() => smoothScroll('registerTop')} >
                                        S'inscrire
                                        {
                                                    loading && <div className="spinner-border text-light spinner-border-sm" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div>
                                                }
                                    </button>
                                    <div className="submitting"></div>
                                </div>
                            </div>
                        
 
                        </Form>
                                    )}
                </Formik>
                    </div>
                </div> }
            </section>
                        {/* Car details Modal */}

                        <Modal 
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={ termesShow }
                onHide={() => setTermesShow(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Termes et conditions d'utilisation
                    </Modal.Title> 
                </Modal.Header>
                <Modal.Body>
                <div className="container main-wrapper"> 
	<div className="lte-text-page margin-default">
        <div className="row centered">
        	            <div className="col-xl-12 col-xs-12">
				<article id="post-15967" className="post-15967 page type-page status-publish hentry">
	<div className="entry-content clearfix" id="entry-div">
				<div data-elementor-type="wp-page" data-elementor-id="15967" className="elementor elementor-15967">
						<div className="elementor-inner">
				<div className="elementor-section-wrap">
									<section className="elementor-section elementor-top-section elementor-element elementor-element-10d77d5 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="10d77d5" data-element_type="section">
						<div className="elementor-container elementor-column-gap-default">
							<div className="elementor-row">
					<div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-f61e2a4" data-id="f61e2a4" data-element_type="column">
			<div className="elementor-column-wrap elementor-element-populated">
							<div className="elementor-widget-wrap">
						<div className="elementor-element elementor-element-6d9e4a9 elementor-widget elementor-widget-html" data-id="6d9e4a9" data-element_type="widget" data-widget_type="html.default">
				<div className="elementor-widget-container">
			<ol>
	<li><b>Acceptation des Conditions</b></li>
	<p>En accédant au site web de AAIC GLOBAL, vous acceptez de vous conformer aux présentes Conditions Générales d'Utilisation.</p>
	<li><b>Utilisation du Site</b></li>
	<p>Vous vous engagez à utiliser le site dans le respect des lois et règlements en vigueur. Toute utilisation à des fins illégales ou malveillantes est strictement interdite.</p>
	<li><b>Propriété Intellectuelle</b></li>
	<p>Le contenu du site, y compris les textes, images, logos, et autres éléments, est protégé par les lois sur la propriété intellectuelle. Toute reproduction ou utilisation non autorisée est interdite.</p>
	<li><b>Responsabilités</b></li>
	<p>AAIC GLOBAL s'efforce de fournir des informations exactes et à jour, mais ne garantit pas l'exactitude ni l'exhaustivité du contenu. AAIC GLOBAL décline toute responsabilité en cas d'interruption ou de dysfonctionnement du site.</p>
	<li><b>Liens vers des Sites Tiers</b></li>
	<p>Le site peut contenir des liens vers des sites tiers. AAIC GLOBAL n'assume aucune responsabilité quant au contenu ou à <Link style={{ color: 'primary', textDecoration: 'underline' }} to='/politiques-de-confidentialités' >la politique de confidentialité</Link> de ces sites.</p>
	<li><b>Protection des Données Personnelles</b></li>
	<p>Les données personnelles collectées sont traitées conformément à la politique de confidentialité disponible sur le site.</p>
	<li><b>Modifications des CGU</b></li>
	<p>AAIC GLOBAL se réserve le droit de modifier les présentes CGU à tout moment. Les utilisateurs seront informés des changements.</p>
	<li><b>Loi Applicable et Juridiction</b></li>
	<p>Les présentes CGU sont régies par la loi togolaise. Tout litige relatif à l'utilisation du site sera de la compétence des tribunaux compétents.</p>
	<p>En utilisant le site, vous acceptez l'ensemble de ces conditions. Si vous n'acceptez pas ces conditions, veuillez ne pas utiliser le site.</p>
</ol>
		</div>
				</div>
						</div>
					</div>
		</div>
								</div>
					</div>
		</section>
									</div>
			</div>
					</div>
			</div>
</article>
            </div>
        	        
        </div>
	</div>

</div>
                </Modal.Body>
                <Modal.Footer>
                {/* <Button onClick={props.onHide}>Close</Button> */}
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default Register