import { FC, useEffect } from 'react';
 
import {
    Outlet, Link
} from "react-router-dom";

import Banner from '../../Layouts/Banner';
import Newsletter from '../../Layouts/Newsletter'; 

import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';

const Legal: FC = () => {  
    return (
        <>  
            <Banner activePage={"Mentins légales"} />
            <section className="ftco-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-lg-12 d-flex services align-self-stretch px-4  "> 
            <div className="elementor-element elementor-element-9badb76 elementor-widget elementor-widget-html" data-id="9badb76" data-element_type="widget" data-widget_type="html.default">
				<div className="elementor-widget-container">
			<ol>
                <li><b>Éditeur du Site</b></li>
                <ul>
                    <li>AAIC GLOBAL</li>
                    <li>Montréal, QC</li>
                    <li>+228 99895053</li>
                    <li> <a href="mailto:contact@aaic-global.org">contact@aaic-global.org</a> </li>
                </ul>
                <li><b>Propriété Intellectuelle </b></li>
                Le contenu du site (textes, images, vidéos, etc.) est la propriété exclusive de AAIC-GLOBAL, sauf indication contraire.
                <li><b>Protection des Données Personnelles </b></li>
                Conformément aux dispositions légales, les utilisateurs ont le droit d'accéder, de rectifier, de mettre à jour ou de supprimer leurs données personnelles. Pour exercer ces droits, les utilisateurs peuvent contacter AAIC-GLOBAL à <a href="mailto:contact@aaic-global.org">contact@aaic-global.org</a>
                <li><b>Cookies</b> </li>
                Le site utilise des cookies pour améliorer l'expérience utilisateur. Les utilisateurs peuvent configurer leurs navigateurs pour refuser les cookies.
                <li><b>Liens Externes</b> </li>
                Le site peut contenir des liens vers des sites externes. AAIC-GLOBAL n'est pas responsable du contenu de ces sites.
                <li><b>Responsabilité</b></li>
                AAIC-GLOBAL s'efforce de fournir des informations exactes et à jour, mais ne peut garantir l'exactitude de toutes les informations présentes sur le site.
                <li><b>Contact</b> </li>
                Pour toute question ou préoccupation concernant le site web, veuillez contacter AAIC-GLOBAL à <a href="mailto:ontact@aaic-global.org">contact@aaic-global.org</a>

                <p>Cette mention légale est susceptible d'être modifiée. Les modifications seront publiées sur le site. En utilisant le site, vous acceptez les termes de cette mention légale.</p>
            </ol>


		</div>
				</div>
                </div>
                </div>
                </div>
                </section>
            <Newsletter />
        </>
    );
}

export default Legal