import axiosInstance from '../../Utils/axios_manager';

export default class ConnexionService {
  
    constructor( ) {
    }

    public register =  (data: Record<string, any>) => {
        return axiosInstance.post('/register.php', data);
    }

    public getClients =  async () => {
        return await axiosInstance.get('/client/list');
    }

    public verifyEmail =  async (email: string) => {
        return await axiosInstance.get('/verify_email.php' + `?email=${email}`);
    }

    public verifyPhoneNumber =  async (email: string) => {
        return await axiosInstance.get('/verify_phone_number.php' + `?phone_number=${email}`);
    }

    public putClient =  (data: Record<string, any>) => {
        return axiosInstance.post('/client/update', data);
    }

    public getCountries =  async () => {
        return await axiosInstance.get('/get_countries.php');
    }
  

}