import { FC, useEffect } from 'react';
 
import {
    Outlet, Link
} from "react-router-dom";

import Banner from '../../Layouts/Banner';
import Newsletter from '../../Layouts/Newsletter';

import Accordion from 'react-bootstrap/Accordion';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';


const About: FC = () => {  
    return (
        <>  
        <Banner activePage={"Qui sommes-nous"} />
        <section id="notreHistorique" className="ftco-section ftco-no-pt bg-light">
                <div className="container">
                    <div className="row d-flex no-gutters">
                        <div className="col-md-6 pl-md-5 py-md-5">
                            <div className="heading-section pl-md-4 pt-md-5">
                                <span className="subheading">Bienvenue chez INVESTMENT CLUB</span>
                                <h2 className="mb-4">L'histoire de notre création</h2>
                                <p>
                                En juillet 2022, un groupe d'investisseurs afro se sont réunis pour réfléchir et bâtir une plateforme d'éducation financière, par les afros pour la communauté des afro Descendants. En Février 2023, ce rêve est devenu réalité, avec la création de l'AIC/ACI, dont le siège est à Montréal, Canada, et dont les représentations sont ouvertes dans une dizaine de pays sur 3 continents (Amérique, Europe, et Afrique).
                                </p>
                                <h4>Organisme à but non lucratif</h4>
                                <ul>
                                    <li>
                                        <b>Siège:</b> Montréal, QC
                                    </li>
                                    <li>
                                        <b>Régions d'intervention:</b> Afrique, Amérique, Europe
                                    </li>
                                    <li>
                                        <b>Clusters (Pays):</b> Canada, États-Unis, Congo, Togo, Côte-d'Ivoire, Tchad, Bénin, France, Haïti, Brésil
                                    </li>
                                    <li>
                                        <b>Année de création:</b> 2023
                                    </li>
                                    <li>
                                        <b>Nombre de membres:</b> 250
                                    </li>
                                </ul> 
                                <Link to="/devenir-un-membre" className="btn btn-primary">
                                    Devenir membre
                                </Link>
                            </div>
                        </div>
                        <div className="col-md-6 d-flex">
                            <div className="img img-video d-flex align-self-stretch align-items-center justify-content-center
                            justify-content-md-center mb-4 mb-sm-0"  >
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/wSZta3phdas" 
                                frameBorder={0} allowFullScreen={true}></iframe>
                                {/* <video controls={true}>
                                    <source src="https://youtu.be/wSZta3phdas" />
                                </video> */}
                            </div>
                        </div>
                        
                    </div>
                </div>
        </section>
        <section id="notreVision" className="ftco-section ftco-no-pt bg-light">
                <div className="container">
                    <div className="row d-flex no-gutters">
                        <div className="col-md-6 d-flex">
                            <div className="img img-video d-flex align-self-stretch align-items-center justify-content-center
                            justify-content-md-center mb-4 mb-sm-0" style={{ backgroundImage: "url(assets/images/vision.jpg)" }}>
                            </div>
                        </div>
                        <div className="col-md-6 pl-md-5 py-md-5">
                            <div className="heading-section pl-md-4 pt-md-5">
                                {/* <span className="subheading">Bienvenue chez INVESTMENT CLUB</span> */}
                                <h2  className="mb-4">Notre vision</h2>
                                <p>
                                    Créer une plateforme d'excellence en éducation financière et fournir des services d'accompagnement d'initiatives en investissement
                                </p>
                                <Link to="/devenir-un-membre" className="btn btn-primary">
                                    Devenir membre
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
        </section>
        <section id="notreMission" className="ftco-section ftco-no-pt bg-light">
                <div className="container">
                    <div className="row d-flex no-gutters">

                        <div className="col-md-6 pl-md-5 py-md-5">
                            <div className="heading-section pl-md-4 pt-md-5">
                                {/* <span className="subheading">Bienvenue chez INVESTMENT CLUB</span> */}
                                <h2 className="mb-4">Notre mission</h2>
                                <p>
                                AFRO INVESTMENT CLUB (AIC), en français Afro Club d'Investissement (ACI), est un club d’investisseurs africains et afro descendants intéressés par différentes formes d’investissements porteurs.
<br/><br/>
Notre but est d’éduquer, informer et sensibiliser nos membres sur des opportunités d’investissements qui s’offrent à eux à travers des formations et autres activités génératrices de revenus.
<br/><br/>
Nous mettons à la disposition de nos membres les outils permettant de rentabiliser leurs investissements.
<br/><br/>
Nous voulons nous donner les moyens de créer des structures ou des entités à buts lucratifs pour et dans l’intérêt de nos membres exclusivement.
</p>
<br/><br/>
<blockquote className="blockquote">
  <p style={{ color: "" }} className="mb-0">" Oser la prospérité et la liberté. Quitter la pauvreté et ses fatalités. "</p>
</blockquote>
                               
                                <Link to="/devenir-un-membre" className="btn btn-primary">
                                    Devenir membre
                                </Link>
                            </div>
                        </div>


                        <div className="col-md-6 d-flex">
                            <div className="img img-video d-flex align-self-stretch align-items-center justify-content-center
                            justify-content-md-center mb-4 mb-sm-0" > 
                                {/* style={{ backgroundImage: "url(assets/images/logo.jpeg)" }} */}
                                <img className='img-responsive' src="assets/images/logo.jpeg" alt="" />
                            </div>
                        </div>
                        
                    </div>
                </div>
        </section>
    <section  className="ftco-counter bg-light ftco-no-pt" id="notreConseilAdmin">
    	<div className="container">
            <div className='heading-section'>
                <h2 style={{ textAlign: 'center' }} className="mb-4 center">
                    Notre Conseil D'administration </h2>
            </div>
			
			<div className="row">
				<div className='col-md-4'></div>
				<div className="col-md-4 d-flex"  >
					<div className="card">
						<img className="card-img-top img-responsive" src={"assets/images/presidente.jpg"} alt="Card image cap" />
						<div className="card-body">
							<h5 className="card-title text-center"><a href="#" className="meta-chat">PRESIDENTE</a></h5>
							<p className="card-text text-center">VIRGINIE SOUKA</p>
						</div>
					</div>
				</div> 
    		</div>
			<br />
			<div className="row">
				<div className="col-md-4 d-flex"  >
					<div className="card">
						<img className="card-img-top img-responsive" src={"assets/images/orphe_olympio.jpg"} alt="Card image cap" />
						<div className="card-body">
							<h5 className="card-title text-center"><a href="#" className="meta-chat">VICE-PRESIDENT AMERIQUE</a></h5>
							<p className="card-text text-center">OFUMILAYO (ORPHE) OLYMPIO</p>
						</div>
					</div>
				</div>
				<div className="col-md-4 d-flex"  >
					<div className="card">
						<img className="card-img-top img-responsive" 
						src={"assets/images/romain_kpodar.jpg"} alt="Card image cap" />
						<div className="card-body">
							<h5 className="card-title text-center"><a href="#" className="meta-chat">VICE-PRESIDENT EUROPE</a></h5>
							<p className="card-text text-center">MR. ROMAIN KPODAR</p>
						</div>
					</div>
				</div> 
				<div className="col-md-4 d-flex" >
					<div className="card">
						<img className="card-img-top img-responsive" 
						src={"assets/images/marie_rose.jpeg"} alt="Card image cap" />
						<div className="card-body">
							<h5 className="card-title text-center"><a href="#" className="meta-chat">VICE-PRESIDENT AFRIQUE</a></h5>
							<p className="card-text text-center">DR. MARIE ROSE POUTYA SAIZONOU</p>
						</div>
					</div>
				</div>
    		</div>
			<br />
			<div className="row">
				<div className="col-md-4 d-flex"  >
					<div className="card">
						<img className="card-img-top img-responsive" src={"assets/images/avatar.jpg"} alt="Card image cap" />
						<div className="card-body">
							<h5 className="card-title text-center"><a href="#" className="meta-chat">SECRETAIRE</a></h5>
							<p className="card-text text-center">MS. KALE AKWEI</p>
						</div>
					</div>
				</div>
				<div className="col-md-4 d-flex"  >
					<div className="card">
						<img className="card-img-top img-responsive" src={"assets/images/avatar.jpg"} alt="Card image cap" />
						<div className="card-body">
							<h5 className="card-title text-center"><a href="#" className="meta-chat">TRESORIER</a></h5>
							<p className="card-text text-center">MR. AYITE AJAVON</p>
						</div>
					</div>
				</div> 
				<div className="col-md-4 d-flex" >
					<div className="card">
						<img className="card-img-top img-responsive" src={"assets/images/ca.jpg"} alt="Card image cap" />
						<div className="card-body">
							<h5 className="card-title text-center"><a href="#" className="meta-chat">CONSEILLER  EN  EDUCATION  &  INTERLLIGENCE  FINANCIERE</a></h5>
							<p className="card-text text-center">MS. OLGA OLYMPIO</p>
						</div>
					</div>
				</div>
    		</div>
			<br />
			<div className="row">
				
				<div className="col-md-4 d-flex"  >
					<div className="card">
						<img className="card-img-top img-responsive" src={"assets/images/rachida_toni.jpg"} alt="Card image cap" />
						<div className="card-body">
							<h5 className="card-title text-center"><a href="#" className="meta-chat">CONSEILLER EN TONTINE</a></h5>
							<p className="card-text text-center">MS. RACHIDA TONI</p>
						</div>
					</div>
				</div> 

				<div className="col-md-4 d-flex" >
					<div className="card">
						<img className="card-img-top img-responsive" src={"assets/images/avatar.jpg"} alt="Card image cap" />
						<div className="card-body">
							<h5 className="card-title text-center"><a href="#" className="meta-chat">CONSEILLER EN BOURSES FINANCIERES</a></h5>
							<p className="card-text text-center">MR. AKOLY (BRICE) DOGBEH-AGBO</p>
						</div>
					</div>
				</div>

				<div className="col-md-4 d-flex"  >
					<div className="card">
						<img className="card-img-top img-responsive" src={"assets/images/avatar.jpg"} alt="Card image cap" />
						<div className="card-body">
							<h5 className="card-title text-center"><a href="#" className="meta-chat">CONSEILLER EN IMMOBILIER</a></h5>
							<p className="card-text text-center">MR. RAYOMOND FOLEY</p>
						</div>
					</div>
				</div>

    		</div>
			<br/>
			<div className="row">
				
				<div className="col-md-4 d-flex"  >
					<div className="card">
						<img className="card-img-top img-responsive" src={"assets/images/avatar.jpg"} alt="Card image cap" />
						<div className="card-body">
							<h5 className="card-title text-center"><a href="#" className="meta-chat">CONSEILLER INVESTISSEMENT PME/PMI</a></h5>
							<p className="card-text text-center">MR. GERALD EXANTUS ALERTE</p>
						</div>
					</div>
				</div> 
			
			</div>

    	</div>
    </section>

	<section className="ftco-counter bg-light ftco-no-pt" id="notreDocumentation">
    	<div className="container">
            <div className='heading-section'>
                <h2 style={{ textAlign: 'center' }} className="mb-4 center">
                Notre Documentation Statutaire, Reglementaire et statistiques </h2>
            </div>
			
			<div className='row'>
				<div className="col-md-2"></div>
				<div className="col-md-8">
					<table className="table">
						<thead>
							<tr>
							<th scope="col">#</th>
							<th scope="col">Titre</th>
							<th scope="col">Télécharger</th> 
							</tr>
						</thead>
						<tbody>
							<tr>
								<th scope="row">1</th>
								<td>ELECTIONS CONSEIL D'ADMINISTRATION - ASSOCIATION AIC Canada - Final2</td>
								<td>
									<a target="_blank" href="https://aaic-global.org/ELECTIONS CONSEIL D'ADMINISTRATION - ASSOCIATION AIC Canada - Final2.pdf" download className='btn btn-primary'>
										Télécharger
									</a>
								</td> 
							</tr>
							<tr>
								<th scope="row">2</th>
								<td>Plan-D'Actions_AAIC - Virginie Souka_ver01avril2023</td>
								<td>
									<a target="_blank" href="https://aaic-global.org/Plan-D'Actions_AAIC - Virginie Souka_ver01avril2023.pdf" download className='btn btn-primary'>
										Télécharger
									</a>
								</td> 
							</tr>
							<tr>
								<th scope="row">3</th>
								<td>PV AG Association Afro Investment Club_VF</td>
								<td>
									<a target="_blank" href="https://aaic-global.org/PV AG Association Afro Investment Club_VF.pdf" download className='btn btn-primary'>
										Télécharger
									</a>
								</td> 
							</tr>
							<tr>
								<th scope="row">4</th>
								<td>STATUTS ASSOCIATION ET REGLEMENT INTERIEUR AAIC Canada - Signes Dr. Denke et Ms. Kale</td>
								<td>
									<a target="_blank" href="https://aaic-global.org/STATUTS ASSOCIATION ET REGLEMENT INTERIEUR AAIC Canada - Signes Dr. Denke et Ms. Kale.pdf" download className='btn btn-primary'>
										Télécharger
									</a>
								</td> 
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</section>

    <section id="notreFaq" className="ftco-section ftco-no-pt bg-light ftco-faqs">
    	<div className="container">
    		<div className="row">
    			<div className="col-lg-6">
    				<div className="img-faqs w-100">
	    				<div className="img mb-4 mb-sm-0" style={{ backgroundImage: "url(assets/images/about_faq_1.jpg)" }}>
	    				</div>
	    				<div className="img img-2 mb-4 mb-sm-0" style={{ backgroundImage: "url(assets/images/about-1.jpg)" }}>
	    				</div>
	    			</div>
    			</div>
    			<div className="col-lg-6 pl-lg-5">
    				<div className="heading-section mb-5 mt-5 mt-lg-0">
    					<span className="subheading">FAQs</span>
	            		<h2 className="mb-3">Foire aux questions</h2>
    				</div>

					<Accordion defaultActiveKey="0">
									<Card>
										<Accordion.Toggle style={{ borderRadius: "4px", backgroundColor: '#edd663', color: "white" }} as={Card.Header} eventKey="0">
											<b> Pourquoi investir ? </b>
										</Accordion.Toggle>
										<Accordion.Collapse eventKey="0">
										<Card.Body>
											Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quae recusandae ratione quam unde vitae voluptate nobis autem natus, quod eos dicta rem provident dolorem facere numquam ipsa ipsam nihil reiciendis.
										</Card.Body>
										</Accordion.Collapse>
									</Card>
									<Card>
										<Accordion.Toggle style={{ borderRadius: "4px", backgroundColor: '#edd663', color: "white" }} as={Card.Header} eventKey="1">
										 <b> Comment investir ? </b>
										</Accordion.Toggle>
										<Accordion.Collapse eventKey="1">
										<Card.Body>
											Lorem ipsum dolor sit, amet consectetur adipisicing elit. Unde, sit. Mollitia quas eum ab doloribus, debitis cumque. Reprehenderit dolore consequuntur, adipisci dolorum consequatur, incidunt maiores rem dignissimos, minima iure ullam!
										</Card.Body>
										</Accordion.Collapse>
									</Card>
									<Card>
										<Accordion.Toggle style={{ borderRadius: "4px", backgroundColor: '#edd663', color: "white" }} 
										as={Card.Header} eventKey="2">
										 <b> Commencer par ou ? </b>
										</Accordion.Toggle>
										<Accordion.Collapse eventKey="2">
										<Card.Body>
											Lorem ipsum dolor sit, amet consectetur adipisicing elit. Unde, sit. Mollitia quas eum ab doloribus, debitis cumque. Reprehenderit dolore consequuntur, adipisci dolorum consequatur, incidunt maiores rem dignissimos, minima iure ullam!
										</Card.Body>
										</Accordion.Collapse>
									</Card>
					</Accordion>

    				{/* <div id="accordion" className="myaccordion w-100" aria-multiselectable="true">

						  <div className="card">
						    <div className="card-header p-0" id="headingOne">
						      <h2 className="mb-0">
						        <a  href="#collapseOne"  className="d-flex py-3 px-4 align-items-center justify-content-between btn btn-link" data-parent="#accordion" data-toggle="collapse" aria-expanded="true" aria-controls="collapseOne">
						        	 <p className="mb-0">How to fixed a problem?</p> 
						          <i className="fa" aria-hidden="true"></i>
						        </a>
						      </h2>
						    </div>
						    <div className="collapse show" id="collapseOne" role="tabpanel" aria-labelledby="headingOne">
						      <div className="card-body py-3 px-0">
						      	<ol>
						      		<li>Far far away, behind the word mountains</li>
						      		<li>Consonantia, there live the blind texts</li>
						      		<li>When she reached the first hills of the Italic Mountains</li>
						      		<li>Bookmarksgrove, the headline of Alphabet Village</li>
						      		<li>Separated they live in Bookmarksgrove right</li>
						      	</ol>
						      </div>
						    </div>
						  </div>

						  <div className="card">
						    <div className="card-header p-0" id="headingTwo" role="tab">
						      <h2 className="mb-0">
							  	<a href="#collapseTwo" className="d-flex py-3 px-4 align-items-center justify-content-between btn btn-link" data-parent="#accordion" data-toggle="collapse" aria-expanded="false" aria-controls="collapseTwo">
						        	<p className="mb-0">How to manage your business loans?</p> 
						          <i className="fa" aria-hidden="true"></i>
						        </a>
						      </h2>
						    </div>
						    <div className="collapse" id="collapseTwo" role="tabpanel" aria-labelledby="headingTwo">
						      <div className="card-body py-3 px-0">
						      	<ol>
						      		<li>Far far away, behind the word mountains</li>
						      		<li>Consonantia, there live the blind texts</li>
						      		<li>When she reached the first hills of the Italic Mountains</li>
						      		<li>Bookmarksgrove, the headline of Alphabet Village</li>
						      		<li>Separated they live in Bookmarksgrove right</li>
						      	</ol>
						      </div>
						    </div>
						  </div>

						  <div className="card">
						    <div className="card-header p-0" id="headingThree" role="tab">
						      <h2 className="mb-0">
						        <Link to="#collapseThree" className="d-flex py-3 px-4 align-items-center justify-content-between btn btn-link" data-parent="#accordion" data-toggle="collapse" aria-expanded="false" aria-controls="collapseThree">
						        	<p className="mb-0">How to grow your investments funds?</p>
						          <i className="fa" aria-hidden="true"></i>
						        </Link>
						      </h2>
						    </div>
						    <div className="collapse" id="collapseThree" role="tabpanel" aria-labelledby="headingTwo">
						      <div className="card-body py-3 px-0">
						      	<ol>
						      		<li>Far far away, behind the word mountains</li>
						      		<li>Consonantia, there live the blind texts</li>
						      		<li>When she reached the first hills of the Italic Mountains</li>
						      		<li>Bookmarksgrove, the headline of Alphabet Village</li>
						      		<li>Separated they live in Bookmarksgrove right</li>
						      	</ol>
						      </div>
						    </div>
						  </div>

						  <div className="card">
						    <div className="card-header p-0" id="headingFour" role="tab">
						      <h2 className="mb-0">
						        <Link to="#collapseFour" className="d-flex py-3 px-4 align-items-center justify-content-between btn btn-link" data-parent="#accordion" data-toggle="collapse" aria-expanded="false" aria-controls="collapseFour">
						        	<p className="mb-0">What are those requirements for businesses?</p>
						          <i className="fa" aria-hidden="true"></i>
						        </Link>
						      </h2>
						    </div>
						    <div className="collapse" id="collapseFour" role="tabpanel" aria-labelledby="headingTwo">
						      <div className="card-body py-3 px-0">
						      	<p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts. Separated they live in Bookmarksgrove right at the coast of the Semantics, a large language ocean.</p>
						      </div>
						    </div>
						  </div>
						</div> */}
	        </div>
        </div>
    	</div>
    </section>
    <Newsletter />
        </>
    );
}

export default About