import { FC, useEffect } from 'react';
 
import {
    Outlet, Link
} from "react-router-dom";

import Banner from '../../Layouts/Banner';
import Newsletter from '../../Layouts/Newsletter'; 

import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';

const Confidential: FC = () => {  
    return (
        <>  
            <Banner activePage={"Politiques de confidentialités"} />
            <section className="ftco-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-lg-12 d-flex services align-self-stretch px-4  ">
                        <section className="elementor-section elementor-top-section elementor-element elementor-element-c2dcfe7 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="c2dcfe7" data-element_type="section">
						<div className="elementor-container elementor-column-gap-default">
							<div className="elementor-row">
					<div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-9c23c71" data-id="9c23c71" data-element_type="column">
			<div className="elementor-column-wrap elementor-element-populated">
							<div className="elementor-widget-wrap">
						<div className="elementor-element elementor-element-9e08324 elementor-widget elementor-widget-html" data-id="9e08324" data-element_type="widget" data-widget_type="html.default">
				<div className="elementor-widget-container">
			<ol>
	<li><b>Collecte d'Informations Personnelles</b></li>
		<ol>
			<li>Informations Fournies Volontairement</li>
		 Nous collectons les informations personnelles que vous fournissez volontairement, telles que votre nom, votre adresse e-mail, votre numéro de téléphone, etc., lorsque vous utilisez notre site web.
			<li>Cookies </li>
			<p>Nous utilisons des cookies pour améliorer l'expérience utilisateur. Ces cookies peuvent collecter des informations sur votre navigation, vos préférences et vos interactions avec notre site.</p>
		</ol>
	<li><b>Utilisation des Informations</b></li>
		<ol>
			<li>Nous utilisons les informations que vous fournissez pour traiter vos demandes, personnaliser votre expérience et vous envoyer des informations pertinentes sur nos produits.</li>
			<li>Les cookies sont utilisés pour analyser les tendances, administrer le site, suivre les mouvements des utilisateurs, et recueillir des informations démographiques sur notre base d'utilisateurs dans son ensemble.</li>
		</ol>
	<li><b>Sécurité des Informations</b></li>
		<ol>
			<li>Nous mettons en œuvre des mesures de sécurité appropriées pour protéger vos informations personnelles contre tout accès non autorisé ou toute modification, divulgation ou destruction non autorisée.</li>
		</ol>
	<li><b>Partage d'Informations</b></li>
		<ol>
			<li>Nous ne partageons pas vos informations personnelles avec des tiers sans votre consentement, sauf si cela est nécessaire pour répondre à une demande que vous avez faite ou pour d'autres raisons légitimes.</li>
		</ol>
	<li><b>Droits des Utilisateurs</b></li>
		<ol>
			<li>Vous avez le droit d'accéder, de corriger, de mettre à jour ou de supprimer vos informations personnelles à tout moment.</li>
		</ol>
	<li><b>Modifications de la Politique de Confidentialité</b></li>
		<ol>
			<li>Nous nous réservons le droit de mettre à jour cette politique de confidentialité à tout moment. Les modifications seront publiées sur cette page.</li>
		</ol>
	<p>En utilisant notre site web, vous acceptez les termes de cette politique de confidentialité. 
        Pour toute question ou préoccupation, veuillez nous contacter à <a href="mailto:ontact@aaic-global.org">contact@aaic-global.org</a></p>
</ol>

		</div>
				</div>
						</div>
					</div>
		</div>
								</div>
					</div>
		</section>
                        </div> 
                    </div> 
                </div>
            </section>
            <Newsletter />
        </>
    );
}

export default Confidential